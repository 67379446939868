<template>
<h1 class="text-center text-3xl">Page Transistions</h1>

<ul class="text-center">

  <li class="flex flex-col">
    <h1 class="py-2">NR.1</h1>

    <router-link to="/pagetransitions1" class=" hover:text-red-600">Example Nr.1</router-link>
  </li>


  <li class="flex flex-col">
    <h1 class="py-2">NR.2</h1>

    <router-link to="/pagetransitions2" class=" hover:text-red-600">Example Nr.2</router-link>
  </li>



  <li class="flex flex-col">
    <h1 class="py-2">NR.3</h1>

    <router-link to="/pagetransitions3" class=" hover:text-red-600">Example Nr.3</router-link>
  </li>

  <li class="flex flex-col">
    <h1 class="py-2">NR.3</h1>

    <router-link to="/pagetransitions4" class=" hover:text-red-600">Example Nr.4</router-link>
  </li>



</ul>

</template>

<script>
export default {
  name: "PageTransistions"
}
</script>

<style scoped>

</style>